import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyB9dLyujoraabsh9tX-m_Tw5wvRpJDayRY",
  authDomain: "shivtexspinning-f4ee8.firebaseapp.com",
  projectId: "shivtexspinning-f4ee8",
  storageBucket: "shivtexspinning-f4ee8.appspot.com",
  messagingSenderId: "320332522552",
  appId: "1:320332522552:web:1bf97a4c69b3273b6872cd",
  measurementId: "G-S1F3HEQQM0"
};

const app = initializeApp(firebaseConfig);
export const database = getFirestore(app)