import React from 'react';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { addDoc, collection } from 'firebase/firestore'
import { database } from '../../firebase';

export const ContactUsForm = () => {

    const [name, setName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [mobile, setMobile] = React.useState<string>("");
    const [comments, setComments] = React.useState<string>("");
    const [disabled, setDisabled] = React.useState<boolean>(false);

    const handleSubmit = async () => {
        setDisabled(true)
        if (name.trim() === "" || email.trim() === "" || mobile.trim() === "" || comments.trim() === "") {
            alert("All fields are required")
        }
        else {
            await addDoc(
                collection(database, 'contact-us'), {
                name: name,
                email: email,
                mobile: mobile,
                comments: comments,
            }
            )
            setName("")
            setEmail("")
            setComments("")
            setMobile("")
            alert("Thank You!")
        }
        setDisabled(false)
    }

    return (
        <div className="container p-3">
            <input
                data-aos="fade-right"
                data-aos-delay={200}
                data-aos-duration={1000}
                value={name}
                type="text"
                className="form-control mb-4"
                placeholder='Full Name*'
                onChange={
                    (e) => {
                        setName(e.target.value)
                    }
                }
            />
            <input
                data-aos="fade-right"
                data-aos-delay={400}
                data-aos-duration={1000}
                type="email"
                value={email}
                className="form-control mb-4"
                placeholder='Email*'
                onChange={
                    (e) => {
                        setEmail(e.target.value)
                    }
                }
            />
            <input
                data-aos="fade-right"
                data-aos-delay={600}
                data-aos-duration={1000}
                value={mobile}
                type="text"
                className="form-control mb-4"
                placeholder='Mobile*'
                onChange={
                    (e) => {
                        setMobile(e.target.value)
                    }
                }
            />
            <input
                data-aos="fade-right"
                data-aos-delay={800}
                data-aos-duration={1000}
                value={comments}
                type="text"
                className="form-control mb-4"
                placeholder='Comments'
                onChange={
                    (e) => {
                        setComments(e.target.value)
                    }
                }
                style={{
                    wordWrap: 'normal'
                }}
            />
            <div className="container text-center w-75">
                <button
                    data-aos="fade-right"
                    data-aos-delay={1000}
                    data-aos-duration={1000}
                    className='btn form-control green-button'
                    disabled={disabled}
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    )
}

export const ContactUs = () => {
    return (
        <>
            <Header />
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-lg-6 mb-3 justify-center flex-column">
                        <h1
                            className='text-main fw-bolder mb-0'
                            data-aos="fade-left"
                            data-aos-delay={200}
                            data-aos-duration={1000}
                        >
                            Work at Shivtex.
                        </h1>
                        <h6
                            className='text-main fw-bolder mt-0 mb-4'
                            data-aos="fade-left"
                            data-aos-delay={500}
                            data-aos-duration={1000}
                        >
                            Learn. Grow. Achieve. Succeed.
                        </h6>
                        <h6
                            className='line-height-wide text-justify p-3 p-lg-0'
                            data-aos="fade-right"
                            data-aos-delay={700}
                            data-aos-duration={1000}
                        >
                            Shivtex is growing at a rapid pace and has space for experienced, mid-career professionals, and young graduates in its human capital base. If you do not find a relevant opportunity here, please feel free to write to us at humancapital@shivtex.com with succinct responses on why do you want to work at Shivtex, what function would you prefer being associated with, and how can you help the firm achieve its vision & mission. If you're your profile is found suitable by the Shivtex team, you will hear from us very soon.
                        </h6>
                    </div>
                    <div className="col-lg-6">
                        <img
                            data-aos="fade-up"
                            data-aos-delay={1000}
                            data-aos-duration={1000}
                            src="/assets/images/work-at-shivtex-first.jpg"
                            alt=""
                            className="img-thumbnail p-0 rounded-3 border-0 bg-transparent"
                        />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-6 p-3 justify-center flex-column bg-light rounded-3">
                        <h2 className='text-main fw-bolder mb-4 text-center'>
                            Enquire
                        </h2>
                        <ContactUsForm />
                    </div>
                    <div className="col-lg-6 justify-center flex-column p-5">
                        <h2
                            className='text-main fw-bolder mb-4 text-center'
                            data-aos="fade-left"
                            data-aos-delay={200}
                            data-aos-duration={1000}
                        >
                            How Can the Shivtex Team Help You?
                        </h2>
                        <h6
                            className='line-height-wide'
                            data-aos="fade-right"
                            data-aos-delay={400}
                            data-aos-duration={1000}
                        >
                            You can get in touch with the Shivtex team here and talk about how we can help you produce value for your textiles products & offerings:
                        </h6>
                        <div
                            className="row mt-2 mb-2 shadow pt-2 pb-2"
                            data-aos="fade-up"
                            data-aos-delay={700}
                            data-aos-duration={1000}
                            style={{
                                borderLeft: '5px solid gray'
                            }}
                        >
                            <div className="col-2 text-end item-center">
                                <img
                                    src="/assets/icons/location-icon.svg"
                                    alt=""
                                    className="img-thumbnail border-0 p-lg-3 bg-transparent"
                                />
                            </div>
                            <div className="col-10 item-center">
                                <p className='m-0 fw-bold'>
                                    Survey No. 230/1, Saijpur Gopalpur,
                                    Near Ranipur Gam, Narol Road,
                                    Ahmedabad, Gujarat, India, 380025.
                                </p>
                            </div>
                        </div>
                        <div
                            className="row mt-2 mb-2 shadow pt-2 pb-2"
                            data-aos="fade-up"
                            data-aos-delay={700}
                            data-aos-duration={1000}
                            style={{
                                borderLeft: '5px solid gray'
                            }}
                        >
                            <div className="col-2 text-end item-center">
                                <img src="/assets/icons/mail-icon.svg" alt="" className="img-thumbnail border-0 p-lg-3 bg-transparent" />
                            </div>
                            <div className="col-10 item-center">
                                <a href="mailto:humancapital@shivtex.com" className='text-decoration-none h6 fw-bold text-dark'>
                                    humancapital@shivtex.com
                                </a>
                            </div>
                        </div>
                        <div
                            className="row mt-2 mb-2 shadow pt-2 pb-2"
                            data-aos="fade-up"
                            data-aos-delay={700}
                            data-aos-duration={1000}
                            style={{
                                borderLeft: '5px solid gray'
                            }}
                        >
                            <div className="col-2 text-end item-center">
                                <img src="/assets/icons/phone-icon.svg" alt="" className="img-thumbnail border-0 p-lg-3 bg-transparent" />
                            </div>
                            <div className="col-10 item-center">
                                <a href="tel:9825005724" className='text-decoration-none h6 fw-bold text-dark'>
                                    +91-9825005724
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}