import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MeasurableExcellenceList } from '../../Constants/MeasurableExcellenceList';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

export const Custodians = () => {

    const [deviceWidth, setDeviceWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener('resize', () => setDeviceWidth(window.innerWidth))
    })

    return (
        <div className="container mt-5 mb-5">
            <h2
                className="text-main fw-bolder mb-3"
                data-aos="fade-left"
                data-aos-delay={200}
                data-aos-duration={1000}
            >
                Custodians to Your Ordering Process
            </h2>
            <h6
                className='text-justify line-height-wide mb-3'
                data-aos="fade-right"
                data-aos-delay={400}
                data-aos-duration={1000}
            >
                Ordering just the perfect yarns can be a little difficult, given the nuances and large quantity of ordering. A minor error in the order can disrupt your manufacturing process. With the Shivtex team by your side, you don't have to worry about the risk of ordering errors.
            </h6>
            {
                deviceWidth < 992 &&
                <h3
                    className="text-main text-center fw-bolder mb-3"
                    data-aos="fade-left"
                    data-aos-delay={200}
                    data-aos-duration={1000}
                >
                    How to Order the Perfect Cotton Yarn?
                </h3>
            }
            <div className="row mt-5 mb-5">
                <div className="col-lg-6 justify-center">
                    <img
                        src={`/assets/images/perfect-cottom.png`}
                        alt=""
                        className="img-thumbnail border-0 p-0 bg-transparent w-100"
                        data-aos="zoom-out"
                        data-aos-delay={500}
                        data-aos-duration={1000}
                    />
                </div>
                <div className="col-lg-6 justify-center flex-column">
                    {
                        deviceWidth >= 992 &&
                        <h2
                            className="text-main text-center fw-bolder mb-3"
                            data-aos="fade-left"
                            data-aos-delay={700}
                            data-aos-duration={1000}
                        >
                            How to Order the Perfect Cotton Yarn?
                        </h2>
                    }
                    {
                        [
                            "Define the Features of the End-Product You are Manufacturing.",
                            "Map the Constraints You Have in Achieving Those Qualities.",
                            "Our Customer Success Team will Help You Define Key Metrics.",
                            "Evaluate Key Metrics: Count, CSP, IPI, COUNTCV, and Elongation",
                            "Ascertain the Optimal Order Size.",
                            "Contact the Shivtex Team."
                        ].map((value, key) => {
                            return (
                                <div
                                    key={key}
                                    className="row"
                                    data-aos="fade-left"
                                    data-aos-delay={1000}
                                    data-aos-duration={1000}
                                >
                                    <div className="col-2 p-2 p-md-4 pt-md-1 pb-md-1">
                                        <img src="/assets/icons/arrow-icon.gif" alt="" className='img-thumbnail border-0 p-0 w-75' />
                                    </div>
                                    <div className="col-10 h6 item-center p-0">{value}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-lg-6 mb-3 justify-center flex-column">
                    <h2
                        className="text-main text-center fw-bolder mb-3"
                        data-aos="fade-left"
                        data-aos-delay={200}
                        data-aos-duration={1000}
                    >
                        How Can Shivtex Help You?
                    </h2>
                    {
                        [
                            "Contact the Shivtex Team.",
                            "Our Customer Success Team will Help You Define Key Metrics.",
                            "Specify Your Quantity Needs and Quality Standards.",
                            "Chart the Timelines and Other Specifications.",
                            "Use the Shivtex Cotton Yarn to Grow Your Business.",
                        ].map((value, key) => {
                            return (
                                <div
                                    key={key}
                                    className="row"
                                    data-aos="fade-right"
                                    data-aos-delay={500}
                                    data-aos-duration={1000}
                                >
                                    <div className="col-2 p-2 p-md-4 pt-md-1 pb-md-1">
                                        <img src="/assets/icons/arrow-icon.gif" alt="" className='img-thumbnail border-0 p-0 w-75' />
                                    </div>
                                    <div className="col-10 h6 item-center p-0">{value}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-lg-6 justify-center">
                    <img
                        src={`/assets/images/shivtex-outer.jpg`}
                        alt=""
                        className="img-thumbnail border-0 p-0 bg-transparent w-100"
                        data-aos="zoom-out"
                        data-aos-delay={800}
                        data-aos-duration={1000}
                    />
                </div>
                <div className="col-lg-12 mt-5 text-center">
                    <Link
                        to="/contact"
                        data-aos="zoom-in"
                        data-aos-delay={200}
                        data-aos-duration={1000}
                        className="green-button"
                        onClick={
                            () => {
                                document.body.scrollTop = 0
                                document.documentElement.scrollTop = 0;
                            }
                        }
                    >
                        Contact us
                    </Link>
                </div>
            </div>
        </div>
    )
}

export const MeasurableExcellence = () => {
    return (

        <div className="container mt-5 mb-5">
            <h2
                className="text-main fw-bolder mb-3"
                data-aos="fade-left"
                data-aos-delay={200}
                data-aos-duration={1000}
            >
                Measurable Excellence Across the Board.
            </h2>
            <h6
                className='mb-4'
                data-aos="fade-right"
                data-aos-delay={500}
                data-aos-duration={1000}
            >
                Besides the Shivtex brand certifying the high quality of our cotton yarns, we have a very clear demarcation on what differentiates our products:
            </h6>
            {
                MeasurableExcellenceList.map((value, key) => {
                    return (
                        <h6
                            className='line-height-wide mb-3 p-2 ps-4 pe-4'
                            style={{
                                borderLeft: '5px solid gray'
                            }}
                            key={key}
                            data-aos="fade-right"
                            data-aos-delay={800}
                            data-aos-duration={1000}
                        >
                            <span className="fw-bolder text-main">{value.title}</span> :&nbsp;
                            {value.description}
                        </h6>
                    )
                })
            }
        </div>
    )
}

export const EfficiencyDrivenQuality = () => {
    const [deviceWidth, setDeviceWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener('resize', () => setDeviceWidth(window.innerWidth))
    })

    return (
        <div className="container mt-5 mb-5 p-3">
            {
                deviceWidth < 992 &&
                <h3
                    className='mb-4 text-main fw-bolder'
                    data-aos="fade-left"
                    data-aos-delay={200}
                    data-aos-duration={1000}
                >
                    Efficiency-Driven Quality.
                </h3>
            }
            <div className="row">
                <div className="col-lg-6 justify-center flex-column">
                    <Carousel
                        fade
                        className='container-fluid p-0'
                        data-aos="fade-up"
                        data-aos-delay={400}
                        data-aos-duration={1000}
                    >
                        {
                            [
                                "product-page-1.jpg",
                                "product-page-2.jpg",
                                "product-page-3.jpg",
                                "product-page-4.jpg"
                            ].map((value, key) => {
                                return (
                                    <Carousel.Item
                                        interval={1500}
                                        key={key}
                                    >
                                        <img
                                            src={`/assets/images/${value}`}
                                            alt=""
                                            className="img-thumbnail border-0 p-0 bg-transparent w-100"
                                        />
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className="col-lg-6 justify-center flex-column">
                    {
                        deviceWidth >= 992 &&
                        <h3
                            className='mb-4 text-main fw-bolder'
                            data-aos="fade-left"
                            data-aos-delay={500}
                            data-aos-duration={1000}
                        >
                            Efficiency-Driven Quality.
                        </h3>
                    }
                    <h6
                        className='text-justify line-height-wide'
                        data-aos="fade-right"
                        data-aos-delay={800}
                        data-aos-duration={1000}
                    >
                        Balancing on the spectrum of high affordability and maintaining the premium quality has been a result of years of continual innovation, research, business process design, and reengineering. Right from day one of our operations, we have thrived to create a system that can consistently produce high-quality open-end cotton yarns, taking up systemic recalibration whenever necessary. In four years of operations, we have more than doubled our production capacity. Today we are producing 12,000 TPA of 100% cotton open-end yarns ranging from 5S to 30S. With our consistent quality production, you can produce excellent quality of knitted and woven fabrics, without having to worry about repeatability, inconsistencies in product features, high-prices, or environment degradation.
                    </h6>
                </div>
            </div>
        </div>
    )
}

export const Products = () => {
    return (
        <>
            <Header />
            <EfficiencyDrivenQuality />
            <MeasurableExcellence />
            <Custodians />
            <Footer />
        </>
    )
}