import React from 'react'
import { Carousel } from 'react-bootstrap'
import { OneThreadAtATimeTable, SymphonyOfConsistencyTable } from '../../Constants/MeasureInQualityTable'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'

export const FiberEngineering = () => {
    return (
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-lg-9">
                    <h3
                        className='text-main fw-bolder'
                        data-aos="fade-left"
                        data-aos-delay={300}
                        data-aos-duration={1000}
                    >
                        Configured for Precise Fibre Engineering.
                    </h3>
                    {
                        [
                            "The manufacturing process at Shivtex is nothing less than a symphonic orchestra coming together. To an untrained eye, it is mere amalgamation of machines on the factory floor. The fine quality of our products stand as a proxy to the fibre-level engineering precision we have achieved.",
                            "Designing an effective manufacturing facility is like looking at a puzzle from all the angles & dimensions at the same time. Harnessing this insight, the Shivtex team focused on how each fibre goes from the input-level processes to the product-output generating systems.",
                            "To put it on a singular platform of excellence, the team focused on one key question - How to make a yarn from a fibre? With that, the team got to the drawing-board and optimized each node of the process to make sure the output quality was unswerving and high-performing on all the customer metrics."
                        ].map((value, key) => {
                            return (
                                <div
                                    key={key}
                                    className="h6 mt-3 mb-3 line-height-wide"
                                    data-aos="fade-right"
                                    data-aos-delay={600}
                                    data-aos-duration={1000}
                                >
                                    {value}
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-lg-3 hv-center">
                    <img
                        src="/assets/icons/engineering-icon.gif"
                        alt=""
                        className="img-thumbnail border-0 p-0 bg-transparent w-50"
                        data-aos="zoom-in"
                        data-aos-delay={800}
                        data-aos-duration={1000}
                    />
                </div>
            </div>
        </div>
    )
}

export const Consistancy = () => {

    const deviceWidth = window.innerWidth

    return (
        <div className="container mt-5 mb-5">
            {
                window.innerWidth < 992 &&
                <h3
                    className='text-main text-center fw-bolder'
                    data-aos="fade-left"
                    data-aos-delay={200}
                    data-aos-duration={1000}
                >
                    12000 MT of Consistency - Every Year.
                </h3>
            }
            <div className="row">
                <div className="col-lg-3 hv-center">
                    <img
                        src="/assets/icons/consistency-icon.svg"
                        alt=""
                        className="img-thumbnail border-0 p-0 bg-transparent w-50"
                        data-aos="zoom-out"
                        data-aos-delay={300}
                        data-aos-duration={1000}
                    />
                </div>
                <div className="col-lg-9">
                    {
                        deviceWidth >= 992 &&
                        <h3
                            className='text-main mb-4 fw-bolder'
                            data-aos="fade-left"
                            data-aos-delay={500}
                            data-aos-duration={1000}
                        >
                            12000 MT of Consistency - Every Year.
                        </h3>
                    }
                    <h6
                        className='line-height-wide'
                        data-aos="fade-left"
                        data-aos-delay={500}
                        data-aos-duration={1000}
                    >
                        To a problem solver, numbers tell the best stories. And the story told by the Shivtex manufacturing facility is that of consistency. With Strategic Planning in place, the inputs are measured to meet the designated output qualities. Based on that, the manufacturing process is configured to perfection. At the end of each manufacturing cycle, the system performs a rigorous check of the output quality. Taking into account each one of these, the process is calibrated again. The process is repeated till the level of excellence desired by the customer and promised by Shivtex is not achieved. Once that is done, the process is streamlined to ensure high output quantity consistency across the board.
                    </h6>
                </div>
            </div>
        </div>
    )
}

export const MeasuringInQuality = () => {
    return (
        <div className="container mt-5 mb-5">
            <h2
                className='text-main fw-bolder'
                data-aos="fade-left"
                data-aos-delay={200}
                data-aos-duration={1000}
            >
                Measuring Excellence in Quality,
            </h2>
            <h5
                className='text-main fw-bolder mt-4'
                data-aos="fade-left"
                data-aos-delay={500}
                data-aos-duration={1000}
            >
                One Thread at a Time.
            </h5>
            <table
                className='table table-hover table-bordered table-striped mt-2'
                data-aos="fade-right"
                data-aos-delay={800}
                data-aos-duration={1000}
            >
                <tbody>
                    {
                        OneThreadAtATimeTable.map((value, key) => {
                            return (
                                <tr key={key}>
                                    <td className='ps-3 pe-3 text-center fw-bold h6 align-middle'>{key + 1}</td>
                                    <td className='w-25 text-center fw-bold h6 align-middle'>{value.name}</td>
                                    <td className='w-75 align-middle'>{value.description}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <h5
                className='text-main fw-bolder mt-4'
                data-aos="fade-left"
                data-aos-delay={500}
                data-aos-duration={1000}
            >
                Symphony of Consistency.
            </h5>
            <h6
                className="text-justify line-height-medium mb-3"
                data-aos="fade-right"
                data-aos-delay={700}
                data-aos-duration={1000}
            >
                We go beyond the local standards of excellence and that reflects even in our manufacturing process. Our entire manufacturing technology suite has been imported from Germany to ensure our output matches all the reverential global standards.
            </h6>
            <table
                className='table table-hover table-bordered table-striped mt-2'
                data-aos="fade-right"
                data-aos-delay={1000}
                data-aos-duration={1000}
            >
                <tbody>
                    <tr className='text-center'>
                        <th></th>
                        <th>MACHINE / PROCESS</th>
                        <th>MAKE &amp; MODEL</th>
                    </tr>
                    {
                        SymphonyOfConsistencyTable.map((value, key) => {
                            return (
                                <tr key={key}>
                                    <td className='ps-3 pe-3 text-center fw-bold h6 align-middle'>{key + 1}</td>
                                    <td className='w-25 text-center fw-bold h6 align-middle'>{value.name}</td>
                                    <td className='w-75 align-middle'>{value.description}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export const WhatDoesMean = () => {
    return (
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-lg-9">
                    <h2
                        className='text-main fw-bolder'
                        data-aos="fade-left"
                        data-aos-delay={200}
                        data-aos-duration={1000}
                    >
                        What Does This Mean for You?
                    </h2>
                    <h6
                        className='line-height-wide text-justify'
                        data-aos="fade-right"
                        data-aos-delay={500}
                        data-aos-duration={1000}
                    >
                        The process, no matter how well-engineered, is eventually tested by customer satisfaction it is able to garner. Our manufacturing process is doing it every year.
                    </h6>
                    {
                        [
                            "High-quality fibre to yarn process.",
                            "Manufactured efficiently to keep your costs under control.",
                            "Repeatability to ensure uniformity across product ranges.",
                            "Lower impact on the environment.",
                            "The modular manufacturing process for easy turnaround.",
                            "Rigorous quality checks to avoid reworks."
                        ].map((value, key) => {
                            return (
                                <div
                                    key={key}
                                    className="row"
                                    data-aos="fade-left"
                                    data-aos-delay={800}
                                    data-aos-duration={500}
                                >
                                    <div className="col-2 p-2 p-md-5 pt-md-1 pb-md-1">
                                        <img src="/assets/icons/arrow-icon.gif" alt="" className='img-thumbnail border-0 p-0' />
                                    </div>
                                    <div className="col-10 h6 item-center p-0">{value}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-lg-3 hv-center">
                    <img
                        src="/assets/icons/customer-relationship.svg"
                        alt=""
                        className="img-thumbnail border-0 p-0 bg-transparent w-100"
                        data-aos="zoom-in"
                        data-aos-delay={1000}
                        data-aos-duration={1000}
                    />
                </div>
            </div>
        </div>
    )
}

export const ImageCarousel = () => {
    return (
        <div className='container mt-5 mb-5'>
            <Carousel>
                {
                    [
                        "banner-1.png",
                        "banner-2.png"
                    ].map((value, key) => {
                        return (
                            <Carousel.Item
                                interval={1500}
                                key={key}
                            >
                                <img
                                    src={`/assets/images/${value}`}
                                    alt=""
                                    className="img-thumbnail border-0 p-0 bg-transparent"
                                />
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}


export const Quality = () => {
    return (
        <>
            <Header />
            <ImageCarousel />
            <FiberEngineering />
            <Consistancy />
            <MeasuringInQuality />
            <WhatDoesMean />
            <Footer />
        </>
    )
}