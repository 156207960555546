export const ValueFrameworkList = [
    {
        image: 'frame-1.png',
        description: 'Consistency is our biggest differentiator.'
    },
    {
        image: 'frame-2.png',
        description: 'We prefer excellence at one thing, over mediocrity at scale.'
    },
    {
        image: 'frame-3.png',
        description: 'Our customer\'s needs would guide our business strategy.'
    }
]