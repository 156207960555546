import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { LinkList } from '../../Constants/LinkList';
import { ContactUsForm } from '../ContactUs/ContactUs';

export const Header = () => {
    const [drawer, setDrawer] = React.useState<boolean>(false)
    const [visible, setVisible] = React.useState<boolean>(false)
    const [modal, setModal] = React.useState<boolean>(false)
    const path = useLocation().pathname
    const [deviceWidth, setDeviceWidth] = React.useState<number>(window.innerWidth);

    React.useEffect(() => {
        window.addEventListener('resize', () => { setDeviceWidth(window.innerWidth) })
    }, [])

    React.useEffect(() => {
        if (!modal) {
            setTimeout(() => {
                setModal(true)
            }, (120 * 1000))
        }
    }, [modal])

    return (
        <>
            {
                deviceWidth >= 1200 ?
                    <div className='container-fluid shadow mb-2'>
                        <div className='row p-2'>
                            <div className="col-xl-5 hv-center">
                                <img src="/assets/images/logo.png" alt="" className="w-50 img-thumbnail border-0 bg-transparent" />
                            </div>
                            <div className="col-xl-7 hv-center">
                                {
                                    LinkList.map((value, key) => {
                                        return (
                                            <Link
                                                key={key}
                                                to={value.link}
                                                className={`
                                                text-decoration-none text-uppercase
                                                ${key === (LinkList.length - 1) ? 'me-0' : 'me-3'}
                                                ${path === value.link ? 'link-active' : 'link'}
                                            `}
                                            >
                                                {value.name}
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="container-fluid shadow pt-2 pb-2 mb-3">
                            <div className="row">
                                <div className="col-9">
                                    <img src="/assets/images/logo.png" alt="" className="w-100 img-thumbnail border-0 bg-transparent" />
                                </div>
                                <div className="col-3 hv-center">
                                    <span
                                        onClick={
                                            () => {
                                                setDrawer(true)
                                                setTimeout(() => {
                                                    setVisible(true)
                                                }, 500)
                                            }
                                        }
                                    >
                                        <img src="/assets/icons/menu-icon.svg" alt="" className="img-thumbnail w-75 p-3" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`
                                ${!drawer ? 'drawer' : 'drawer-open'}
                            `}
                        >
                            {visible &&
                                <div className="container p-3">
                                    <div className="container text-end">
                                        <img
                                            src="/assets/icons/close-icon.svg"
                                            alt=""
                                            className="img-thumbnail border-0 p-0 bg-transparent close-icon"
                                            onClick={
                                                () => {
                                                    setVisible(false)
                                                    setDrawer(false)
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="container">
                                        {
                                            LinkList.map((value, key) => {
                                                return (
                                                    <Link
                                                        key={key}
                                                        to={value.link}
                                                        className={`
                                                        text-decoration-none text-uppercase d-block mb-2
                                                        ${path === value.link ? 'mobile-link-active' : 'mobile-link'}
                                                    `}
                                                    >
                                                        {value.name}
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </>
            }
            <Modal
                animation
                centered
                size='lg'
                show={modal}
                id="contact_modal"
                onHide={
                    () => {
                        setModal(false)
                    }
                }
                className='rounded-3'
            >
                <Modal.Header className="p-3 bg-main" closeButton>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-2 text-end">
                                <img
                                    src="/assets/images/favocon.png"
                                    alt=""
                                    className="img-thumbnail border-0 p-0"
                                />
                            </div>
                            <div className="col-10 item-center text-start">
                                {
                                    deviceWidth < 720 ?
                                        <h6
                                            className='text-white m-0 p-0'
                                        >
                                            SHIVTEX SPINNING PVT. LTD.
                                        </h6>
                                        :
                                        <h4
                                            className='text-white m-0 p-0'
                                        >
                                            SHIVTEX SPINNING PVT. LTD.
                                        </h4>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm />
                </Modal.Body>
            </Modal>
        </>
    )
}