import React from 'react'
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom'
import { Home } from './Components/Home/Home'
import './Assets/css/styles.css'
import 'aos/dist/aos.css'
import Aos from 'aos'
import { Quality } from './Components/Quality/Quality'
import { AboutUs } from './Components/AboutUs/AboutUs'
import { ContactUs } from './Components/ContactUs/ContactUs'
import { Products } from './Components/Products/Products'
import { OurStory } from './Components/OurStory/OurStory'
import { NotFoundPage } from './Components/NotFoundPage/NotFoundPage'

export const Routes = () => {
    React.useEffect(() => {
        Aos.init()
    }, [])
    return(
        <Router>
            <Switch>
                <Route path="/" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/quality" element={<Quality />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/products" element={<Products />} />
                <Route path="/story" element={<OurStory />} />
                <Route path="*" element={<NotFoundPage />} />
            </Switch>
        </Router>
    )
}