export const OneThreadAtATimeTable = [
    {
        name: "LAB INST. 1",
        description: "MAG HFT FOR COTTON TESTING WITH FULLY AUTOMATIC"
    },
    {
        name: "LAB INST. 2",
        description: "PREMIER IQ 2 LX FOR YARN & SLIVER EVENESS TESING."
    },
    {
        name: "LAB INST. 3",
        description: "MAG CSP TESTER FOR LEA STRENGTH TESTING."
    },
    {
        name: "LAB INST. 4",
        description: "MAG TRASH ANALYSER FOR COTTON TRASH TESTING"
    },
]

export const SymphonyOfConsistencyTable = [
    {
        name: "BLOW ROOM",
        description: "TRUTEZSCHLER WITH CLC3 & CONTAMINATION CLEARER MXU 10+ DUST SEPERATOR DUSTEX DX"
    },
    {
        name: "CARD",
        description: "TRUTEZSCHLER TC 10"
    },
    {
        name: "DRAW FRAME",
        description: "TRUTEZSCHLER TD 8 AND TD 9"
    },
    {
        name: "OPEN END",
        description: "SCHLAFHORST AUTOCORO ACO 9 / SE 20 (552 ROTORS / MC.) WITH WAXING DEVICE, COROLAB SQ YARNS CLEARER. AND MULTI COUNT"
    },
    {
        name: "CONDITIONING",
        description: "SEIGER YARN CONIDITIONING PLANT"
    },
]