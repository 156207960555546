export const AssuranceList = [
    {
        name: 'Thread Level Quality Engineering.',
        icon: 'quality-engineering.svg',
        description: 'Our manufacturing technology has been designed to give you thread-level precision. We configure the quality and the quantity at the smallest unit-level to deliver exactly what you need.'
    },
    {
        name: 'Purity in Every Thread.',
        icon: 'purity.svg',
        description: 'Shivtex produces only the finest quality of 100% pure cotton open-end yarn to enhance your product\'s quality, even before you produce it.'
    },
    {
        name: 'A Team of Dreamers, Achievers, and Weavers.',
        icon: 'team.svg',
        description: 'Our team is full of engineers, business operators, innovators, and high-octane executives who are passionate about textiles and weaving the Shivtex success story.'
    },
]