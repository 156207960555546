export const AboutUsPromiseList = [
    {
        image: 'pro-1.png',
        description: 'Immaculate Product Quality All the products leaving the Shivtex facility will perform well across all the spectrum of rigorous KPIs set to deliver maximum value to our customers.'
    },
    {
        image: 'pro-2.png',
        description: 'Consistency and Continuity We shall deliver our quality of offerings consistently, even as our operations are scaled and our teams grow. No amount of complexity will hinder the continuity in the relationships we have with our customers.'
    },
    {
        image: 'pro-3.png',
        description: 'Human Capital Investments The people at Shivtex are the ones who drive our growth and deliver on our vision. We will invest in the training, growth, and comprehensive development of our people working across various facets of the business.'
    },
    {
        image: 'pro-4.png',
        description: 'Collective Growth We will measure our growth by the prosperity of our customers, team members, contributions to the societal welfare, and our distinct efforts in improving the environment we inhabit.'
    },
    {
        image: 'green-eye.png',
        description: 'Process-Driven Accomplishments We will not focus on workarounds and fixes. We will develop processes and systems that stand the test of time and deliver on the vision we have set for ourselves.'
    },
]