export const MeasurableExcellenceList = [
    {
        title: "Wide Range",
        description: "Our cotton yarns range from 5S to 30S, spread across different counts to give you absolute freedom in calibrating your production process based on your needs. With Shivtex open-end cotton yarns, you can produce the exact quality of fabric you desire without compromising on the price features."
    },
    {
        title: "Production Consistency",
        description: "The wide range does not get in way of producing consistently. Our automated processes allow us to be equally consistent across all production scales and variants. You can now expect the same high quality, no matter what variant or quantity you need for your fabric."
    },
    {
        title: "Optimal Tensile Strength",
        description: "Tensile Strength decides how well the yarn will blend into the aimed end-product quality. Too high of strength can make it difficult to weave or knit the product. The weak tensile ability makes the yarn break in the middle of the production. We have perfected the process to give you the precise tensile strength you seek for economically producing high-quality fabric."
    },
    {
        title: "Globally Revered Quality",
        description: "Our yarns are used by textile manufacturers and intermediaries across the globe, with major clientele present in China, Bangladesh, Peru and Turkey"
    },
    {
        title: "High Output Capacity",
        description: "With our operations continually scaling, we have the systems in place to match your needed output capacity, without compromising even on the smallest metrics of quality."
    },
]