export const LinkList = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'About us',
        link: '/aboutus'
    },
    {
        name: 'Products',
        link: '/products'
    },
    {
        name: 'Quality',
        link: '/quality'
    },
    {
        name: 'Our Story',
        link: '/story'
    },
    {
        name: 'Contact',
        link: '/contact'
    },
]