import React from 'react'
import { Link } from 'react-router-dom'
import { LinkList } from '../../Constants/LinkList'

export const Footer = () => {
    return (
        <>
            <div className="container-fluid p-3 bg-dark">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-3 mb-2 mb-lg-0 hv-center">
                        <div className="row">
                            <div className="col-2">
                                <img src="/assets/images/favocon.png" alt="" className="img-thumbnail bg-transparent border-0 p-0" />
                            </div>
                            <div className="col-10">
                                <h4 className='footer-large-font'>SHIVTEX SPINNING PVT. LTD. </h4>
                                <p className='text-justify footer-small-font'>Passionate about textiles, Shivtex Spinning Pvt. Ltd. Has been weaving growth stories since beginning in the year 2016. While manufacturing of open and cotton yarn, we assure both quality and value to our clientele. Our company is helmed by professionals with more than 15 years of experience in the cotton yarn industry. They bring the expertise and proficiency to the company. Our time-honoured clientele respects our for timely delivery and customer service.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 mb-2 mb-lg-0 ps-3 pe-0 ps-lg-5 pe-lg-5 justify-center flex-column">
                        <h4 className='footer-large-font'>Useful Links</h4>
                        <div>
                            {
                                LinkList.map((value, key) => {
                                    return (
                                        <div key={key}>
                                            <Link
                                                to={value.link}
                                                className='footer-link'
                                            >
                                                {value.name}
                                            </Link><br />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-lg-3 mb-2 mb-lg-0 ps-0 pe-0 ps-lg-5 pe-lg-5">
                        <h4 className='footer-large-font text-center mb-3'>Contact</h4>
                        <div className="hv-center flex-column" style={{ height: 'max-content' }}>
                            <div className="row">
                                <div className="col-3 text-end">
                                    <img src="/assets/images/location-icon.gif" alt="" className="footer-icon" />
                                </div>
                                <div className="col-9 hv-center">
                                    <p className='footer-small-font'>
                                        <b>SHIVTEX SPINNING PVT. LTD.</b><br />
                                        Survey No. 230/1, Saijpur Gopalpur,
                                        Near Ranipur Gam, Narol Road,
                                        Ahmedabad, Gujarat, India, 380025.
                                    </p>
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="col-3 text-end">
                                    <img src="/assets/images/call-icon.gif" alt="" className="footer-icon" />
                                </div>
                                <div className="col-9 item-center">
                                    <a className='footer-link' style={{ textTransform: 'none' }} href='tel:9825005724'>+91-9825005724</a>
                                </div>
                            </div>
                            <div className="row w-100 mt-2">
                                <div className="col-3 text-end">
                                    <img src="/assets/images/email-icon.gif" alt="" style={{ width: '75%' }} className="footer-icon" />
                                </div>
                                <div className="col-9 item-center">
                                    <a className='footer-link' style={{ textTransform: 'none' }} href='mailto:director@shivtex.co.in'>director@shivtex.co.in</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 mb-2 mb-lg-0 ps-0 pe-0 ps-lg-5 pe-lg-5 flex-column hv-center">
                        <h4 className='footer-large-font text-center mb-3'>Connect with us</h4>
                        <div className="row">
                            <div className="col-3">
                                <a href="/#" target='_blank'>
                                    <img src="/assets/icons/instagram-icon.svg" alt="" className="img-thumbnail border-0 bg-transparent w-100" />
                                </a>
                            </div>
                            <div className="col-3">
                                <a href="/#" target='_blank'>
                                    <img src="/assets/icons/facebook-icon.svg" alt="" className="img-thumbnail border-0 bg-transparent w-100" />
                                </a>
                            </div>
                            <div className="col-3">
                                <a href="/#" target='_blank'>
                                    <img src="/assets/icons/linkedin-icon.svg" alt="" className="img-thumbnail border-0 bg-transparent w-100" />
                                </a>
                            </div>
                            <div className="col-3">
                                <a href="/#" target='_blank'>
                                    <img src="/assets/icons/twitter-icon.svg" alt="" className="img-thumbnail border-0 bg-transparent w-100" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
            <h6
                className="text-light text-center p-2 m-0"
                style={{
                    background: "#B99B5A"
                }}
            >
                &copy; <a href='https://outleadsolutions.com' className='outlead-link'>OutLead Solutions</a>. All rights reserved
            </h6>
        </>
    )
}