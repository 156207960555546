import React from 'react';
import { Carousel } from 'react-bootstrap';
import { HeadsList } from '../../Constants/HeadsList';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

export const Vision = () => {
    return (
        <div className="container mt-5 mb-5">
            <div className="row">
                {
                    [
                        {
                            image: 'values-icon.svg',
                            name: 'Value Framework',
                            description: "Our value framework is an amalgamation of our key differentiators working in conjunction with the Shivtex Promise, designed to help us march towards our mission and eventually accomplish our vision."
                        },
                        {
                            image: 'vision-icon.svg',
                            name: 'Vision',
                            description: "The wide range does not get in way of producing consistently. Our automated processes allow us to be equally consistent across all production scales and variants. You can now expect the same high quality, no matter what variant or quantity you need for your fabric."
                        },
                        {
                            image: 'mission-icon.svg',
                            name: 'Mission',
                            description: "Deploying technology, collective growth agenda, customer value-generation, process-driven approach, and stellar work ethics to achieve every facet of the vision."
                        },
                    ].map((value, key) => {
                        return (
                            <div className="col-lg-4 p-3">
                                <div
                                    className="card shadow-lg p-3 h-100"
                                    data-aos="fade-up"
                                    data-aos-delay={200}
                                    data-aos-duration={1500}
                                >
                                    <div className="container text-center mb-3">
                                        <img
                                            src={`/assets/icons/${value.image}`}
                                            alt=""
                                            className="img-thumbnail border-0 p-0 bg-transparent"
                                        />
                                    </div>
                                    <h4
                                        className='text-main text-center mt-3'
                                    >
                                        {value.name}
                                    </h4>
                                    <h6
                                        className='text-center p-2'
                                    >
                                        {value.description}
                                    </h6>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const Heads = () => {
    return (
        <div className="container mt-5 mb-5">
            <h2
                className="text-main fw-bolder mb-3"
                data-aos="fade-left"
                data-aos-delay={200}
                data-aos-duration={1500}
            >
                Heads and Hands That Are Building & Nourishing Shivtex.
            </h2>
            <h6
                className="text-justify line-height-wide mb-3"
                data-aos="fade-right"
                data-aos-delay={500}
                data-aos-duration={1500}
            >
                The founding and management teams bring together years of global experience in running engineering, textile and adjacent businesses with finesse. Their process oriented approach to establishing best-practices and optimizing them with every turn of operations has brought Shivtex onto a trajectory of sustainable and exponential growth in no time.
            </h6>

            {
                HeadsList.map((value, key) => {
                    return (
                        <div
                            className="container mt-3 p-2 ps-3 pe-3"
                            key={key}
                            style={{
                                borderLeft: '5px solid gray'
                            }}
                            data-aos="fade-right"
                            data-aos-delay={800}
                            data-aos-duration={1500}
                        >
                            <h5
                                className='fw-bolder mb-2 text-main'
                            >
                                {value.name}
                            </h5>
                            <p
                                className='mt-2 text-justify'
                            >
                                {value.description}
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const OneStep = () => {
    return (
        <div className="container mt-5 mb-5">
            <h2
                className="text-main fw-bolder mb-4"
                data-aos="fade-left"
                data-aos-delay={200}
                data-aos-duration={1500}
            >
                One Step for Shivtex. One Giant Leap for Textiles.
            </h2>
            <h6
                className="text-justify line-height-wide mb-3"
                data-aos="fade-right"
                data-aos-delay={500}
                data-aos-duration={1500}
            >
                In 2016, members of the founding team met to discuss some recurrent issues they had witnessed in the textiles industry. The talked about a common issue - it was difficult to get consistent quality of global standards at costeffective prices without reworks in the cotton yarn segment. At first, they were baffled. How can three people coming from different verticals of the industry highlight the same problem? Instead of waiting any further, they took their inspiration to create a platform of excellence and took the matter in their own hands by creating Shivtex Spinning Private Limited (SSPL).
            </h6>
            <h6
                className="text-justify line-height-wide mb-3"
                data-aos="fade-right"
                data-aos-delay={500}
                data-aos-duration={1500}
            >
                Initially, the company was challenged by the market dynamics like every other great institution in its infancy stage. The company bootstrapped its way up and created a wide network of customers across the globe, who revered the quality of cotton yarns supplied by Shivtex. As the company's name started resonating in different segments of the market, growth became a companion. New team-members joined, the vision expanded, and operational capacities nearly doubled in size.
            </h6>
            <h6
                className="text-justify line-height-wide mb-3"
                data-aos="fade-right"
                data-aos-delay={500}
                data-aos-duration={1500}
            >
                In the journey of creating a process to solve the target problem, the Shivtex founding team ended up creating a company that is producing globally revered quality of cotton yarn, supplied at cost-effective prices at the behest of operational efficiencies, technology driven manufacturing, and a gritty team.
            </h6>
        </div>
    )
}

export const ImageCarousel = () => {
    return (
        <div
            className="container mt-5 mb-5"
            data-aos="zoom-in"
            data-aos-duration={1500}
        >
            <Carousel
                fade
            >
                {
                    [
                        "out-storyn1.png",
                        "out-storyn2.png",
                        "story-5.jpg",
                        "story-6.png",
                        "story-7.png",
                    ].map((value, key) => {
                        return (
                            <Carousel.Item
                                interval={1500}
                                key={key}
                            >
                                <img
                                    src={`/assets/images/${value}`}
                                    alt=""
                                    className="img-thumbnail border-0 p-0 bg-transparent"
                                />
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

export const OurStory = () => {
    return (
        <>
            <Header />
            <ImageCarousel />
            <OneStep />
            <Heads />
            <Vision />
            <Footer />
        </>
    )
}