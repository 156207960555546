export const HeadsList = [
    {
        name: "Navin Saraogi, Director",
        description: "Mr Saraogi holds BE in Mechanical Engineering and has over 15 years of experience in managing companies, projects, and teams of varied scales and sizes across the textiles industry. His visionary leadership along with operational expertise have highly contributed to firm's accelerated growth in a brief span."
    },
    {
        name: "Sanjay Bindal, Director",
        description: "Mr Bindal is one of the founding-team members whose vision brought Shivtex to life. A commerce graduate, he brings a total of more than 17 years of experience in the textiles industry. He has also led several revered projects and teams in the civil engineering space. His astute business acumen and understanding of capital allocation have helped Shivtex attain sustainable momentum of expansion in a highly competitive market."
    },
    {
        name: "Aman Agrawal, Director",
        description: "Mr Agrawal, the youngest member of the founding team, brings the technology-driven and process-oriented approach of the Shivtex operations. A software engineering graduate from Nirma University and an MBA from Kingston University in London, he adds the energy, analytical rigor, and technological edifice to key strategic and financial decisions at Shivtex."
    },
    {
        name: "Dinesh Bansal, Director",
        description: "Mr Bansal is the illustrious example of the collective growth agenda at the firm. He brings him immense experience in handling complex projects and large teams in the textiles industry. His administrative expertise and bottom-up knowledge are delivering incremental efficiencies at Shivtex."
    },
    {
        name: "Abhishek Agrawal, Director",
        description: "Mr Abhishek Agrawal happens to be one of the youngest Directors at Shivtex. He is an MSc Finance alumni of the Kingston University in London and has over two years of dedicated experience in the textiles industry. By deploying his objective and accurate analytical skills, he is garnering value for the firm at the production, domestic marketing as well as exports for the firm."
    },
]