import React from 'react';
import { AboutUsPromiseList } from '../../Constants/AboutUsPromiseList';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

export const AboutUs = () => {

    const deviceWidth = window.innerWidth

    return (
        <>
            <Header />
            <div className="container p-3 text-center mt-5 mb-5">
                <img
                    src="/assets/images/directors-about.png"
                    alt=""
                    className="img-thumbnail border-0 p-0 bg-transparent"
                    data-aos="zoom-out"
                    data-aos-delay={300}
                    data-aos-duration={1000}
                />
                <h2
                    className='text-main mt-3 fw-bolder'
                    data-aos="fade-left"
                    data-aos-delay={500}
                    data-aos-duration={1000}
                >
                    We Commit to Promising Growth.
                </h2>
                <h6
                    className='text-center line-height-medium mt-2'
                    data-aos="fade-right"
                    data-aos-delay={700}
                    data-aos-duration={1000}
                >
                    Shivtex was born out of a vision to create efficient, quality, and globally revered cotton yarns. What may seem ostentatious to the world, is the aim of our team's work ethics, skills, and inspiration to create value for our customers. Such aspirations can be delivered only when there is an established foundation of values. The Shivtex Promise is our set of values that guide us in our operational decision-making and strategic thinking:
                </h6>
                <div className="row mt-5 mb-5">
                    {
                        AboutUsPromiseList.map((value, key) => {
                            return (
                                <div
                                    key={key}
                                    className={`
                                        ${key % 5 === 3 || key % 5 === 4 ? 'col-lg-6' :
                                            deviceWidth < 992 || key % 3 === 2 ? 'col-lg-4' : 'col-lg-4 border-end'}
                                        mb-5
                                    `}
                                >
                                    {
                                        (key % 5 === 3 || key % 5 === 4) ?
                                            <div className="row">
                                                {
                                                    key % 5 === 3 &&
                                                    <div className="col-lg-4"></div>
                                                }
                                                <div
                                                    className={`
                                                    col-lg-8
                                                    ${key % 5 === 4 || deviceWidth < 992 ? 'border-0' : 'border-end'}
                                                `}
                                                >
                                                    <img
                                                        src={`/assets/images/${value.image}`}
                                                        alt=""
                                                        className="img-thubnail border-0 p-0 bg-transparent"
                                                        data-aos="zoom-in"
                                                        data-aos-delay={300}
                                                        data-aos-duration={1000}
                                                    />
                                                    <h6
                                                        className='text-center p-2 line-height-wide mt-3'
                                                        data-aos="fade-right"
                                                        data-aos-delay={600}
                                                        data-aos-duration={1000}
                                                    >
                                                        {value.description}
                                                    </h6>
                                                </div>
                                                {
                                                    key % 5 === 4 &&
                                                    <div className="col-lg-4"></div>
                                                }
                                            </div>
                                            :
                                            <>
                                                <img
                                                    src={`/assets/images/${value.image}`}
                                                    alt=""
                                                    className="img-thubnail border-0 p-0 bg-transparent"
                                                    data-aos="zoom-in"
                                                    data-aos-delay={300}
                                                    data-aos-duration={1000}
                                                />
                                                <h6
                                                    className='text-center p-2 line-height-wide mt-3'
                                                    data-aos="fade-right"
                                                    data-aos-delay={600}
                                                    data-aos-duration={1000}
                                                >
                                                    {value.description}
                                                </h6>
                                            </>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}