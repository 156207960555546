export const CertificateList = [
    {
        name: "Global Organic Textile Standard",
        image: "certification-1.png",
        description: "GOTS is a textile production certification that limits the use of toxic bleaches, dyes and other chemical inputs during the production process of textiles. It is internationally recognized as the toughest organic textile standard because it goes far beyond verifying the organic farming process to include every step of manufacturing."
    },
    {
        name: "The Cotton Textiles Export Promotion Council",
        image: "certification-2.png",
        description: "Texprocil provides regular updates on international product trends, trade related issues, advances in technology and the latest developments in the industry, as well as existing and emerging markets. It also undertakes regular market research, organises participation in international trade fairs, holds its own Buyer Seller Meets and facilitates international trade missions in India and other countries"
    },
    {
        name: "Better Cotton Initiative",
        image: "certification-3.png",
        description: "The Better Cotton Standard System is a holistic approach to sustainable cotton production which covers all three pillars of sustainability: environmental, social and economic. Each of the elements - from the Principles and Criteria to the monitoring mechanisms which show results and impact - work together to support the Better Cotton Standard System, and the credibility of Better Cotton and BCI. The system is designed to ensure the exchange of good practices, and to encourage the scaling up of collective action to establish Better Cotton as a sustainable mainstream commodity."
    },
    {
        name: "Global Recycled Standard",
        image: "certification-4.png",
        description: "The GRS is intended to meet the needs of companies looking to verify the recycled content of their products (both finished and intermediate) and to verify responsible social, environmental and chemical practices in their production. The objectives of the GRS are to define requirements to ensure accurate content claims and good working conditions, and that harmful environmental and chemical impacts are minimised. This includes companies in ginning, spinning, weaving and knitting, dyeing and printing and stitching in more than 50 countries."
    },
]