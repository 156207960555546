export const PromiseList = [
    {
        image: 'pro-1.png',
        description: 'Quality has been the top parameter in producing every thread in the yarn.'
    },
    {
        image: 'pro-2.png',
        description: 'Research-driven automated processes have been deployed to maintain consistency.'
    },
    {
        image: 'pro-3.png',
        description: 'Our product range serves primarily the customers and the environment, then our business.'
    },
    {
        image: 'pro-4.png',
        description: 'Our team is the asset we leverage to produce value for our customers.'
    },
]