import React from 'react';
import { Link } from 'react-router-dom';
import { AssuranceList } from '../../Constants/AssuranceList';
import { CertificateList } from '../../Constants/CertificateList';
import { PromiseList } from '../../Constants/PromiseList';
import { ValueFrameworkList } from '../../Constants/ValueFrameworkList';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

const ProductSpecificationList = [
    "Wide Range",
    "Production Consistency",
    "Optimal Tensile Strength",
    "Globally Revered Quality",
    "High Output Capacity"
]

export const ValueFramework = () => {
    return (
        <div className="container p-3 mt-5 mb-5">
            <div className="row">
                <div className="col-lg-6 mb-3 justify-center flex-column">
                    <h2
                        className='text-main fw-bolder text-center mb-4'
                        data-aos="fade-up"
                        data-aos-delay={200}
                        data-aos-duration={1000}
                    >Value Framework</h2>
                    <div className="container">
                        {
                            ValueFrameworkList.map((value, key) => {
                                return (
                                    <div className="row mt-3" key={key}>
                                        <div className="col-3 text-end">
                                            <img
                                                src={`/assets/images/${value.image}`}
                                                alt=""
                                                className="img-thumbnail border-0 p-3 bg-main rounded-circle"
                                                style={{
                                                    height: '75px',
                                                    width: '75px'
                                                }}
                                                data-aos="fade-right"
                                                data-aos-delay={500}
                                                data-aos-duration={1000}
                                            />
                                        </div>
                                        <div className="col-8 item-center">
                                            <h6
                                                data-aos="fade-right"
                                                data-aos-delay={500}
                                                data-aos-duration={1000}
                                            >
                                                {value.description}
                                            </h6>
                                        </div>
                                        <div className="col-1"></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-lg-6 hv-center">
                    <img
                        src={`/assets/images/fram-image.png`}
                        alt=""
                        className="img-thumbnail border-0 rounded-3"
                        data-aos="fade-up"
                        data-aos-delay={800}
                        data-aos-duration={1000}
                    />
                </div>
            </div>
        </div>
    )
}

export const VisionMission = () => {
    return (
        <div className="container-fluid bg-secondary text-white p-3 pt-5 pb-5 mt-5 mb-5">
            <div className="container">
                <div className="row text-center">
                    <div className="col-lg-1"></div>
                    <div
                        className="col-lg-4 p-4"
                        data-aos='zoom-in'
                        data-aos-delay={200}
                        data-aos-duration={1000}
                    >
                        <h2 className='text-start fw-bold hv-center mb-3'>
                            <img src="/assets/images/eye.png" alt="" className="img-thumbnail border-0 bg-transparent p-0 me-2" style={{ width: '10%' }} />
                            Vision
                        </h2>
                        <h6 className='line-height-medium'>
                            To establish Shivtex as an institution that produces a consistent quality of cotton open end yarn loved and cherished across the globe.
                        </h6>
                    </div>
                    <div className="col-2"></div>
                    <div
                        className="col-lg-4 p-4"
                        data-aos='zoom-in'
                        data-aos-delay={400}
                        data-aos-duration={1000}
                    >
                        <h2 className='text-start fw-bold hv-center mb-3'>
                            <img src="/assets/images/arc.png" alt="" className="img-thumbnail border-0 bg-transparent p-0 me-2" style={{ width: '10%' }} />
                            Mission
                        </h2>
                        <h6 className='line-height-medium'>
                            To create a system of trust, absolute efficiency, and affordable quality for the Shivtex customer-base in India and abroad.
                        </h6>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
        </div>
    )
}

export const OurStory = () => {
    return (
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-4">
                    <img
                        src={`assets/images/story-image.png`}
                        alt=""
                        className="img-thumbnail border-0 p-0 bg-transparent"
                        data-aos="zoom-out"
                        data-aos-duration={1500}
                    />
                </div>
                <div className="col-lg-6 justify-center flex-column p-2">
                    <h2
                        className='text-start text-main fw-bolder mb-3'
                        data-aos="fade-down"
                        data-aos-delay={200}
                        data-aos-duration={1500}
                    >
                        Our Story
                    </h2>
                    <h6
                        className='text-justify line-height-wide mb-5'
                        data-aos="fade-right"
                        data-aos-delay={400}
                        data-aos-duration={1500}
                    >
                        Shivtex Spinning was founded in the year 2016 after the founding team saw a scathing need for quality, consistent, and affordable cotton open end yarn produced with Industry 4.0-ready agile manufacturing systems. The team had been in the cotton yarn manufacturing industry for over 15 years and set out on a journey to bring all of that experience to use in achieving what had not been practiced in the industry at a large scale - a focused product line that is produced at the behest of sustainable farming practices, and optimized to reduce enterprise carbon footprint, while still ensuring the quality parameters of each customer.
                    </h6>
                    <div className="container text-start p-0 mt-3 mb-3">
                        <Link
                            to='/story'
                            className="green-button"
                            data-aos="zoom-out"
                            data-aos-delay={700}
                            data-aos-duration={1500}
                            onClick={
                                () => {
                                    document.body.scrollTop = 0
                                    document.documentElement.scrollTop = 0;
                                }
                            }
                        >
                            See more
                        </Link>
                    </div>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </div>
    )
}

export const Promises = () => {
    return (
        <div
            className="container-fluid pt-5 pb-5 mt-5 mb-5 bg-secondary"
        >

            <div className="container">
                <h2 className='text-center text-white fw-bolder mb-5'>The Shivtex Promise</h2>
                <div className="row">
                    {
                        PromiseList.map((value, key) => {
                            return (
                                <div
                                    key={key}
                                    className={`
                                        col-lg-3 p-3 text-center hv-center flex-column
                                        ${key % 4 !== 3 ? 'border-end' : 'border-0'}
                                    `}
                                >
                                    <img
                                        src={`assets/images/${value.image}`}
                                        alt=""
                                        className="img-thumbnail border-0 p-0 bg-transparent"
                                        data-aos="zoom-out"
                                        data-aos-duration={1500}
                                    />
                                    <h6
                                        className='text-white mt-4'
                                        data-aos="fade-up"
                                        data-aos-duration={2000}
                                    >
                                        {value.description}
                                    </h6>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export const WithTheRight = () => {
    return (
        <div className="container mt-5 mb-5">
            <div className='row'>
                <div className="col-lg-6">
                    <h2
                        className='fw-normal'
                        data-aos="zoom-out"
                        data-aos-delay={300}
                        data-aos-duration={2000}
                    >
                        With the right
                    </h2>
                    <h4
                        className='fw-bold text-main'
                        data-aos="fade-up-left"
                        data-aos-delay={500}
                        data-aos-duration={2000}
                    >
                        Technology, Talent
                    </h4>
                    <h4
                        className='fw-bold text-main'
                        data-aos="fade-up-right"
                        data-aos-delay={500}
                        data-aos-duration={2000}
                    >
                        and Quality Assessment
                    </h4>
                    <h6
                        data-aos="fade-right"
                        data-aos-delay={700}
                        data-aos-duration={2000}
                    >
                        In place, we are able to produce cotton open-end yarn that:
                    </h6>
                    <div className="row mt-2 p-1">
                        <div className="col-1 p-2">
                            <img
                                src="/assets/icons/arrow-icon.gif"
                                alt=""
                                className='img-thumbnail border-0 p-0'
                                data-aos="zoom-out"
                                data-aos-delay={500}
                                data-aos-duration={2000}
                            />
                        </div>
                        <div
                            className="col-11 hv-center h6"
                            data-aos="fade-right"
                            data-aos-delay={800}
                            data-aos-duration={2000}
                        >
                            Extensive procurement and bale management to produce 100% contamination-free open-end cotton yarns
                        </div>
                    </div>
                    <div className="row mt-3 p-1">
                        <div className="col-1 p-2">
                            <img
                                src="/assets/icons/arrow-icon.gif"
                                alt=""
                                className='img-thumbnail border-0 p-0'
                                data-aos="zoom-out"
                                data-aos-delay={500}
                                data-aos-duration={2000}
                            />
                        </div>
                        <div
                            className="col-11 hv-center h6"
                            data-aos="fade-right"
                            data-aos-delay={800}
                            data-aos-duration={2000}
                        >
                            The arduously engineered manufacturing systems ensure that you get consistent quality across all orders and scales.
                        </div>
                    </div>
                    <div className="row mt-3 p-1">
                        <div className="col-1 p-2">
                            <img
                                src="/assets/icons/arrow-icon.gif"
                                alt=""
                                className='img-thumbnail border-0 p-0'
                                data-aos="zoom-out"
                                data-aos-delay={500}
                                data-aos-duration={2000}
                            />
                        </div>
                        <div
                            className="col-11 hv-center h6"
                            data-aos="fade-right"
                            data-aos-delay={800}
                            data-aos-duration={2000}
                        >
                            Our open-end spinning system allows us to produce relatively larger quantities of products while maintaining quality and cost-effectiveness.
                        </div>
                    </div>
                    <h6
                        className='mt-4'
                        data-aos="fade-right"
                        data-aos-delay={900}
                        data-aos-duration={1500}
                    >We export our products to China, Peru, Columbia, Turkey, Bangkok, and Bangladesh and have strong trade relations in Rajasthan, Gujarat, Maharashtra, Tamil Nadu, and Punjab.</h6>
                </div>
                <div className="col-lg-6">
                    <img
                        src="/assets/images/map.png"
                        alt=""
                        className='img-thumbnail border-0 p-0'
                        data-aos="zoom-in"
                        data-aos-delay={1000}
                        data-aos-duration={1500}
                    />
                </div>
            </div>
        </div>
    )
}

export const Assurance = () => {
    const [deviceWidth, setDeviceWidth] = React.useState<number>(window.innerWidth)
    React.useEffect(() => {
        window.addEventListener('resize', () => { setDeviceWidth(window.innerWidth) })
    }, [])
    return (
        <div className="container mt-5 mb-5">
            <h2
                className='text-main text-center fw-bolder mb-5'
                data-aos="fade-right"
                data-aos-delay={200}
                data-aos-duration={500}
            >
                Quality Assurance
            </h2>
            <div className="row">
                {
                    AssuranceList.map((value, key) => {
                        return (
                            <div className="col-lg-4" key={key}>
                                <div className="container text-center">
                                    <img
                                        src={`/assets/icons/${value.icon}`}
                                        alt=""
                                        className={`
                                            img-thumbnail p-0 border-0 bg-transparent
                                            ${deviceWidth >= 1200 ? 'w-50' : 'w-75'}
                                        `}
                                        data-aos="fade-up"
                                        data-aos-delay={400}
                                        data-aos-duration={500}
                                    />
                                </div>
                                <h6
                                    className='text-center text-main mt-4 mb-3 fw-bolder'
                                    data-aos="fade-left"
                                    data-aos-delay={700}
                                    data-aos-duration={500}
                                >{value.name}</h6>
                                <h6
                                    className='text-justify ps-3 pe-3'
                                    data-aos="fade-right"
                                    data-aos-delay={1200}
                                    data-aos-duration={500}
                                >
                                    {value.description}
                                </h6>
                            </div>
                        )
                    })
                }
                <div className="container text-center p-0 mt-3">
                    <Link
                        to='/quality'
                        className="green-button"
                        data-aos="zoom-out"
                        data-aos-delay={300}
                        data-aos-duration={500}
                        onClick={
                            () => {
                                document.body.scrollTop = 0
                                document.documentElement.scrollTop = 0;
                            }
                        }
                    >
                        See more
                    </Link>
                </div>
            </div>
        </div>
    )
}

export const OurProducts = () => {
    return (
        <div className="container mt-5 mb-5 p-3">
            <div className="row">
                <div className="col-lg-6 hv-center">
                    <img
                        src="/assets/images/yarn-img.jpg"
                        alt=""
                        className="img-thumbnail p-0 border-0 bg-transparent"
                        data-aos="zoom-in"
                        data-aos-delay={400}
                        data-aos-duration={500}
                    />
                </div>
                <div className="col-lg-6 justify-center flex-column">
                    <h2
                        className='text-main fw-bolder mb-3'
                        data-aos="fade-right"
                        data-aos-delay={600}
                        data-aos-duration={500}
                    >
                        Products
                    </h2>
                    <h6
                        className='text-justify'
                        data-aos="fade-right"
                        data-aos-delay={900}
                        data-aos-duration={500}
                    >
                        Shivtex is one of the few manufacturers in India that focus extensively on producing cotton open-end yarn. Having entered at an opine time in the industry allowed us to bring economies of scale to make our products affordable while using the finest of engineering processes to ensure market-revered product quality.
                    </h6>
                    <div className="row mt-3 mb-3">
                        {
                            ProductSpecificationList.map((value, key) => {
                                return (
                                    <div className="col-lg-6" key={key}>
                                        <div className="row">
                                            <div className="col-2 p-2">
                                                <img src="/assets/icons/arrow-icon.gif" alt="" className='img-thumbnail border-0 p-0' />
                                            </div>
                                            <div
                                                className="col-10 h6 item-center p-0"
                                                data-aos="fade-left"
                                                data-aos-delay={300}
                                                data-aos-duration={500}
                                            >{value}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="container text-center p-0 mt-3">
                        <Link
                            to='/products'
                            className="green-button"
                            data-aos="zoom-out"
                            data-aos-delay={300}
                            data-aos-duration={500}
                            onClick={
                                () => {
                                    document.body.scrollTop = 0
                                    document.documentElement.scrollTop = 0;
                                }
                            }
                        >
                            See more
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Certificate = () => {
    const [deviceWidth, setDeviceWidth] = React.useState<number>(window.innerWidth)
    React.useEffect(() => {
        window.addEventListener('resize', () => { setDeviceWidth(window.innerWidth) })
    }, [])
    return (
        <div className="container-fluid p-0 mt-5 mb-5">
            <h2 className='text-main text-center fw-bolder'>Certificates</h2>
            <img
                src={`/assets/icons/line-up.svg`}
                alt=""
                className="img-thumbnail p-0 border-0 bg-transparent w-100"
            />
            {
                CertificateList.map((value, key) => {
                    return (
                        <div className='row' key={key}>
                            <div className="col-lg-3"></div>
                            {
                                (deviceWidth < 992) &&
                                <div className="col-lg-2 hv-center">
                                    <img
                                        src={`/assets/images/${value.image}`}
                                        alt=""
                                        className="img-thumbnail border-0 p-0 bg-transparent w-50"
                                        data-aos='zoom-in'
                                        data-aos-delay={200}
                                        data-aos-duration={300}
                                    />
                                </div>
                            }
                            {
                                (key % 2 === 0 && deviceWidth >= 992) &&
                                <div className="col-lg-2 hv-center">
                                    <img
                                        src={`/assets/images/${value.image}`}
                                        alt=""
                                        className="img-thumbnail border-0 p-0 bg-transparent w-50"
                                        data-aos='zoom-in'
                                        data-aos-delay={200}
                                        data-aos-duration={300}
                                    />
                                </div>
                            }
                            <div className="col-lg-4 hv-center mt-3 p-4">
                                <div>
                                    <h5
                                        className='text-main fw-bold text-center mb-4'
                                        data-aos='fade-right'
                                        data-aos-delay={400}
                                        data-aos-duration={300}
                                    >
                                        {value.name}
                                    </h5>
                                    <h6
                                        className='text-justify'
                                        data-aos='fade-right'
                                        data-aos-delay={600}
                                        data-aos-duration={300}
                                    >
                                        {value.description}
                                    </h6>
                                </div>
                            </div>
                            {
                                (key % 2 === 1 && deviceWidth >= 992) &&
                                <div className="col-lg-2 hv-center">
                                    <img
                                        src={`/assets/images/${value.image}`}
                                        alt=""
                                        className="img-thumbnail border-0 p-0 bg-transparent w-50"
                                        data-aos='zoom-in'
                                        data-aos-delay={800}
                                        data-aos-duration={300}
                                    />
                                </div>
                            }
                            <div className="col-lg-3"></div>
                            {
                                <img
                                    src={`/assets/icons/${key % 2 === 0 ? 'line-down.svg' : 'line-up.svg'}`}
                                    alt=""
                                    className="img-thumbnail p-0 border-0 bg-transparent m-0"
                                />
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export const Home = () => {
    return(
        <>
            <Header />
            <Assurance />
            <OurProducts />
            <Certificate />
            <WithTheRight />
            <Promises />
            <OurStory />
            <VisionMission />
            <ValueFramework />
            <Footer />
        </>
    )
}