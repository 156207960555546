import React from 'react';
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
    return (
        <div
            className="container hv-center flex-column"
            style={{
                height: '100vh'
            }}
        >
            <img
                src="/assets/images/shivtex-logo.png"
                alt=""
                className="img-thumbnail p-0 border-0 bg-transparent"
            />
            <h1 className='text-danger mb-4 text-center'>
                404 - Page Not Found
            </h1>
            <h5
                className='text-main line-height-wide text-center'
            >
                Please check URL address you have entered<br />
                <Link to="/">
                    Click Here
                </Link>&nbsp;
                to go on Home Page.
            </h5>
        </div>
    )
}